<template>
    <div>

        <!-- {{ flag }} -->
        <div v-if="flag == 0">
            <span class="badge badge-warning">Pengajuan Ditinjau Ulang</span>
        </div>

        <div v-else-if="flag == 1">
            <span class="badge badge-primary">Pengajuan Disetujui (1/2)</span>
        </div>

        <div v-else-if="flag == 2">
            <span class="badge badge-primary text-white">Pengajuan Disetujui (2/2)</span>
        </div>

        <div v-else-if="flag == 3">
            <span class="badge badge-info text-white">Pengajuan Diproses</span>
        </div>

        <div v-else-if="flag == 4">
            <span class="badge badge-success text-white">Pengajuan Selesai</span>
        </div>

        <div v-else-if="flag == 8">
            <span class="badge badge-secondary">Pengajuan Dibatalkan</span>
        </div>

        <div v-else-if="flag == 10">
            <span class="badge badge-dark">Pengajuan Ditolak</span>
        </div>

        <div v-else-if="flag == 11">
            <span class="badge badge-danger">Pekerjaan Dijadwalkan Ulang</span>
        </div>

        <div v-else-if="flag == 12">
            <span class="badge badge-success">Petugas Hadir ({{ countAbsensi }}/{{ countUM }})
            </span>
        </div>

        <div v-else-if="flag == 13">
            <span class="badge badge-success">Menunggu Penilaian</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ["flag", "countUM", "countAbsensi"]
}
</script>