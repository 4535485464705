<template>
    <div>
        <div class="row">
            <div class="col-12">
                <span class="mb-1 d-block">Foto Bukti <sup class="text-danger">*</sup></span>
            </div>
            <div class="col-6 col-sm-4 col-md-3 mb-3" v-for="item in imagePreview" :key="item.preview">
                <div class="image-preview-container">
                    <button @click="removePreviewImage(item.preview, item.file)" class="btn-remove-image">
                        <i class="fa fa-times"></i>
                    </button>
                    <expandable-image class="image-preview" :src="item.preview" />
                </div>
            </div>
            <div class="col-6 col-sm-4 col-md-3 mb-3">
                <label for="images" class="choose-image-action">
                    <input type="file" accept="image/*" id="images" class="d-none" multiple @change="handleChangeImages">
                    <i class="fa fa-image icon-choose"></i>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6">
                <span class="mb-1 d-block">Video Bukti <sup>optional</sup></span>
                <div class="form-group wrapper-video-view">
                    <label for="video">
                        <input @change="handleChangeVideo" type="file" id="video" class="d-none" accept="video/*">
                        <div class="icon-choose-video d-nonex">
                            <i class="fa fa-file-video-o icon-choose"></i>
                        </div>
                    </label>
                    <div v-if="videoPreview" class="video-preview">
                        <button @click="removeVideoSelected" class="btn-remove-video">
                            <i class="fa fa-times"></i>
                        </button>
                        <video controls>
                            <source :src="videoPreview" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-lg-12 d-flex justify-content-center">
                <star-rating v-model="formRating.rating" value="0" :show-rating="false" :glow="3"
                    :rounded-corners="true"></star-rating>
            </div>
            <div class="col-lg-12">
                <b-form-textarea rows="3" v-model="formRating.keterangan" class="my-3"
                    placeholder="Tulis ulasan"></b-form-textarea>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
            <b-button :disabled="isLoading" @click="$bvModal.hide('m-upload-bukti')" class="mr-2"
                variant="dark">Batal</b-button>
            <b-button :disabled="isLoading" @click="handleSubmit" variant="pgiBtn">
                <i v-if="isLoading" class="fa fa-spin fa-spinner"></i>
                Submit
            </b-button>
        </div>
    </div>
</template>

<script>
import axiosClient from '../../../config/api';

export default {
    data() {
        return {
            isLoading: false,
            formRating: {
                rating: null,
                keterangan: "",
            },
            form: {
                files: [],
                video: [],
            },
            videoPreview: null,
            imagePreview: [],
        };
    },
    computed: {
        selectedId() {
            return this.$store.state.pengajuan.selected_id
        }
    },
    methods: {

        // IMAGE HANDLER
        handleChangeImages(e) {
            this.fileErrorMessage = ''
            const files = e.target.files;
            this.createPreviewImage(files)
        },
        createPreviewImage(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                this.form.files.push(file);
                let blobURL = URL.createObjectURL(file);
                this.imagePreview.push({
                    preview: blobURL,
                    file: file
                })
            }
        },


        // VIDEO HANDLER
        handleChangeVideo(e) {
            this.fileErrorMessage = ''
            const videoFile = e.target.files[0]
            this.form.video = e.target.files
            this.createPreviewVideo(videoFile)
        },
        createPreviewVideo(file) {
            let blobURL = URL.createObjectURL(file);
            this.videoPreview = blobURL
        },
        removeVideoSelected() {
            this.videoPreview = null
            this.form.video = []
        },


        removePreviewImage(preview, file) {
            const newPreview = this.imagePreview.filter(i => i.preview !== preview)
            const newFile = []
            for (let i = 0; i < this.form.files.length; i++) {
                const item = this.form.files[i];
                if (item === file) {
                    newFile.push(item)
                }
            }
            this.imagePreview = newPreview
            this.form.files = newFile
        },

        // HANDLE SUBMIT
        async handleSubmit() {
            this.isLoading = true
            // CEK APAKAH ADA FOTO
            if (!this.form.files.length) {
                this.$helper.toastErr(this, "Silahkan upload minimal 1 foto!")
                return
            }

            // CEK APAKAH ADA RATING DANA TEXT
            if (!this.formRating.rating || !this.formRating.keterangan) {
                this.$helper.toastErr(this, "Silahkan berikan rating dan keterangan!")
                return
            }

            Promise.all([this.uploadBukti(), this.submitUlasan()])
                .then(() => {
                    this.$store.dispatch("getDaftarPengajuan");
                    this.$helper.toastSucc(this, "Sukses Unggah bukti dan memberi ulasan")
                    this.isLoading = false
                    this.$bvModal.hide('m-upload-bukti')
                })
                .catch((error) => {
                    console.log(error);
                    this.$helper.toastErr(this, "Gagal Unggah bukti dan memberi ulasan")
                    this.isLoading = false
                    this.$bvModal.hide('m-upload-bukti')
                });

        },
        async uploadBukti() {
            const form = new FormData()
            const files = this.form.files;
            const videos = this.form.video;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                form.append(`files[${i}]`, file)
            }
            for (let i = 0; i < videos.length; i++) {
                const video = videos[i];
                form.append(`video[${i}]`, video)
            }
            try {
                const response = await axiosClient({
                    url: 'internal-memo/memo/uploadBuktiPic/' + this.selectedId,
                    method: 'post',
                    data: form,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                return response.status === 200
            } catch (error) {
                this.$helper.toastErr(this, "Gagal Unggah bukti dan memberi ulasan")
            }
        },
        async submitUlasan() {
            try {
                const response = await axiosClient({
                    url: 'internal-memo/memo/createInternalRating/' + this.selectedId,
                    method: 'post',
                    data: this.formRating
                })
                return response.status === 200
            } catch (error) {
                this.$helper.toastErr(this, "Gagal Unggah bukti dan memberi ulasan")
            }
        }
    }
}
</script>