<template>
    <div>
        <PageLoader v-if="isLoading" />
        <template v-if="daftar_pengajuan">
            <div>
                <b-table small show-empty :items="daftar_pengajuan.data" responsive bordered hover :fields="fields"
                    class="mb-3 pgi-cutom-table table-fpp">
                    <template #empty="scope">
                        <div class="text-center">
                            Data tidak ditemukan
                        </div>
                    </template>
                    <template v-slot:cell(im_number)="row">
                        <div>
                            <span class="d-block">{{ row.item.im_number }}</span>
                            <template v-if="row.item.vendor_type === 1">
                                <small><b>Vendor</b></small>
                            </template>
                        </div>
                    </template>
                    <template v-slot:cell(cabang)="row">
                        <div>
                            <span class="d-block">{{ row.item.cabang.name }}</span>
                            <small>{{ row.item.cabang.kabupaten_kota.name }}</small>
                        </div>
                    </template>
                    <template v-slot:cell(kacab)="row">
                        <div>
                            {{ row.item.kepala_cabang ? row.item.kepala_cabang.name : "-" }}
                        </div>
                        <small>AM : {{ row.item.area_manager ? row.item.area_manager.name : "-" }}</small>
                    </template>
                    <template v-slot:cell(tanggal)="row">
                        {{ row.item.created_at | moment("D MMM Y") }}
                    </template>
                    <template v-slot:cell(kategori_sub)="row">
                        <div>
                            <small>
                                <b>
                                    {{ row.item.kategori_jenis.name }}
                                </b>
                            </small>
                            <span class="d-block">
                                {{ row.item.kategori_sub.name }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:cell(kabkot)="row">
                        {{ row.item.cabang.kabupaten_kota.name }}
                    </template>
                    <template v-slot:cell(status)="row">
                        <BadgeStatusPengajuan :countAbsensi="row.item.memo_maintenance_count_count"
                            :countUM="row.item.total_user_maintenance_count" :flag="row.item.flag" />
                    </template>
                    <template v-slot:cell(aksi)="row">
                        <b-button size="sm" @click="showModalDetail(`${row.item.id}`)" variant="pgiBtn">
                            <i class="fa fa-info-circle"></i>
                        </b-button>
                        <b-button v-if="row.item.flag === 3 && row.item.vendor_type === 0" class="ml-2" size="sm"
                            variant="success" @click="showModalPetugas(`${row.item.id}`)">
                            <i class="fa fa-users"></i>
                        </b-button>
                    </template>
                </b-table>
            </div>
            <div class="d-flex justify-content-center justify-content-md-between">
                <div class="col-md-6 mb-2 mb-md-0">
                    <div class="page-size-container mb-1">
                        <div class="page-size-text">Tampilkan</div>
                        <select @change="changePerPage($event)" v-model="queryParams.per_page" class="form-per-page"
                            id="inputGroupSelect01">
                            <option :value="10">10</option>
                            <option :value="20">20</option>
                            <option :value="30">30</option>
                            <option :value="40">40</option>
                            <option :value="50">50</option>
                        </select>
                        <div class="page-size-text">Baris</div>

                        <div class="ml-2">
                            <span>{{ daftar_pengajuan.from }}</span>
                            <span>-</span>
                            <span>{{ daftar_pengajuan.to }}</span>
                            <span class="mx-1">dari</span>
                            <span>{{ daftar_pengajuan.total }}</span>
                        </div>

                    </div>
                </div>
                <b-pagination class="mb-0" v-model="daftar_pengajuan.current_page" :total-rows="daftar_pengajuan.total"
                    :per-page="daftar_pengajuan.per_page" aria-controls="my-table" @change="changePage"></b-pagination>
            </div>
        </template>
        <b-modal id="m-detail-pengajuan" size="xl" title="Detail Internal Memo" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <ModalDetailPengajuan :query="queryParams" />
        </b-modal>
        <b-modal id="m-detail-petugas" size="md" title="Kode Absensi Petugas" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <ModalDetailPetugas />
        </b-modal>
        <b-modal centered no-fade id="m-upload-bukti" size="md" title="Unggah Bukti dan Penilaian" no-close-on-backdrop
            no-close-on-esc hide-footer>
            <ModalBuktiPekerjaan />
        </b-modal>
    </div>
</template>


<script>
import { mapState } from 'vuex';
import BadgeStatusPengajuan from '../pengajuan/badgeStatusPengajuan.vue';
import ModalDetailPengajuan from '../pengajuan/modalDetailPengajuan.vue';
import ModalDetailPetugas from './modalDetailPetugas.vue';
import ModalBuktiPekerjaan from './modalBuktiPekerjaan.vue';
import PageLoader from '../../pageLoader.vue';
export default {
    components: {
        ModalDetailPengajuan,
        BadgeStatusPengajuan,
        ModalDetailPetugas,
        ModalBuktiPekerjaan,
        PageLoader
    },
    data() {
        return {
            fields: [
                {
                    key: "im_number",
                    label: "No. Pengajuan",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                {
                    key: "tanggal",
                    label: "Tanggal",
                    thClass: "nameOfTheClassThTanggal",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                {
                    key: "cabang",
                    label: "Cabang",
                    thClass: `nameOfTheClassTh`,
                    tdClass: `nameOfTheClassTd`,
                    sortable: true,
                },
                {
                    key: "kacab",
                    label: "KC/AM",
                    thClass: `nameOfTheClassTh`,
                    tdClass: `nameOfTheClassTd`,
                    sortable: true,
                },
                {
                    key: "kategori_sub",
                    label: "Pengajuan",
                    thClass: "nameOfTheClassTh",
                    sortable: true,
                },
                {
                    key: "status",
                    label: "Status",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                {
                    key: "catatan",
                    label: "Keterangan",
                    thClass: "catatan",
                    sortable: true,
                },
                {
                    key: "aksi",
                    label: "Aksi",
                    thClass: "nameOfTheClassThAction",
                    tdClass: "nameOfTheClassTd",
                },
            ],
        };
    },
    methods: {
        async showModalDetail(id) {
            await this.$store.dispatch("getDetailPengajuan", id);
            this.$bvModal.show("m-detail-pengajuan")
        },
        async showModalPetugas(id) {
            await this.$store.dispatch("getDetailPengajuan", id);
            this.$bvModal.show("m-detail-petugas")
        },
        changePage(value) {
            this.$store.dispatch("createQueryParams", {
                ...this.queryParams,
                page: value
            })
            this.$store.dispatch("getDaftarPengajuan", this.queryParams);
        },
        changePerPage(data) {
            this.$store.dispatch("createQueryParams", {
                ...this.queryParams,
                per_page: data.target.value,
            })
            this.$store.dispatch("getDaftarPengajuan", this.queryParams);
        },
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        daftar_pengajuan() {
            return this.$store.state.pengajuan.daftar_pengajuan;
        },
        queryParams() {
            return this.$store.state.global_query.queryParams;
        },
    },
    mounted() {
        this.$store.dispatch("createQueryParams", {
            page: 1,
            per_page: 10,
        })
        this.$store.dispatch("getDaftarPengajuan", { per_page: 10 });
    }
}
</script>

<style>
.btn-popover {
    background-color: #000;
    color: #FFF;
    border: none;
    font-size: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.nameOfTheClassThSelect {
    width: 20px;
}

.form-per-page {
    width: 50px;
    text-align: center;
    border: 1px solid #eee;
    outline: none;
}

@media (max-width:1024px) {
    .pgi-cutom-table td:nth-of-type(1):before {
        content: "No. Pengajuan";
    }

    .pgi-cutom-table td:nth-of-type(2):before {
        content: "Tgl. Pengajuan";
    }

    .pgi-cutom-table td:nth-of-type(3):before {
        content: "Cabang";
    }

    .pgi-cutom-table td:nth-of-type(4):before {
        content: "Pengajuan";
    }

    .pgi-cutom-table td:nth-of-type(5):before {
        content: "Status";
    }

    .pgi-cutom-table td:nth-of-type(6):before {
        content: "Catatan";
    }

    .pgi-cutom-table td:nth-of-type(7):before {
        content: "Aksi";
    }


}

.btn-show-petugas {
    color: #1e73be;
    background-color: transparent;
    padding: 0;
    font-size: 10px;
    font-weight: 600;
    border: none;
    outline: none;

    &:focus {
        outline: none;
    }
}

.catatan {
    width: 130px;
}
</style>