
<template>
    <div>
        <ItemKeyVal label="No. Pengajuan" :value="detail_pengajuan.im_number" />
        <ItemKeyVal label="Cabang" :value="detail_pengajuan.cabang.name" />
        <ItemKeyVal label="Divisi" :value="detail_pengajuan.devisi.nm_Divisi" />
        <ItemKeyVal label="Kategori" :value="detail_pengajuan.kategori_jenis.name" />
        <ItemKeyVal label="Pengajuan" :value="detail_pengajuan.kategori_sub.name" />
        <ItemKeyVal label="Jumlah" :value="detail_pengajuan.qty" />
        <ItemKeyVal label="Catatan" :value="detail_pengajuan.catatan" />
        <ItemKeyVal label="Estimasi Pengerjaan" :value="`${detail_pengajuan.kategori_sub.sla} Hari`" />
        <hr>
        <div>
            <div class="mb-3">
                <b>File Pendukung</b>
            </div>
            <div class="row">
                <div class="col-md-3 col-xl-3" v-for="file in detail_pengajuan.memo_file" :key="file.id">
                    <template v-if="file.flag.toLowerCase() === 'pdf'">PDF</template>
                    <template v-if="file.flag.toLowerCase() === 'foto'">
                        <expandable-image :src="file.path" />
                    </template>
                    <template v-if="file.flag.toLowerCase() === 'video'">
                        <!-- <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe> -->
                        <div class="video-box">
                            <video controls muted autoplay playsinline>
                                <source :src="file.path" type="video/mp4">
                                <source :src="file.path" type="video/ogg">
                                Your browser does not support the video tag.
                            </video>

                        </div>
                    </template>
                </div>
            </div>
        </div>
        <hr>
        <template v-if="detail_pengajuan.vendor_type === 0">
            <div>
                <div class="mb-2">
                    <b>Daftar Material/Bahan</b>
                    <div class="row">
                        <div class="col-lg-6">
                            <b-table bordered hover show-empty small class="" :fields="fieldsMaterial"
                                :items="detail_pengajuan.barang">
                                <template #empty="scope"> Data tidak ditemukan </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div>
                <div class="mb-2">
                    <b>Petugas Pemeliharaan</b>
                </div>
                <div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div v-for="item in detail_pengajuan.memo_maintenance" :key="item.id">
                                <div class="item-petugas" v-for="um in item.user_maintenance" :key="um.id">
                                    <!-- <pre>{{ um }}</pre> -->
                                    <div class="d-flex w-75">
                                        <div class="foto-petugas-img p-1">
                                            <img @error="handleImageError" :src="um.foto" alt="">
                                        </div>
                                        <div class="info-petugas">
                                            <div class="name">
                                                {{ um.nama }}
                                            </div>
                                            <div class="hp">{{ um.no_telp }}</div>
                                            <div class="hp">
                                                {{ item.date | moment("D MMM Y") }}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <small class="d-block">Kode Absen</small>
                                        <b>{{ item.kode }}</b>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="detail_pengajuan.vendor_type === 1">
            <div>
                <div class="mb-2">
                    <b>Informasi Vendor</b>
                </div>
                <ItemKeyVal label="Nama Vendor" :value="detail_pengajuan.internal_memo_vendor.vendor_name" />
            </div>
        </template>
        <hr>
        <div class="d-flex justify-content-end">
            <!-- {{ detail_pengajuan.id }} -->

            <template v-if="role == 'Kepala Cabang' && detail_pengajuan.flag === 0">
                <b-button class="mr-1 ml-1 btn-labeled" variant="danger" @click="confirmCancel(detail_pengajuan.id)"><span
                        class="btn-label"><i class="fa fa-minus-square"></i></span>Batalkan</b-button>
                <b-button variant="warning" class="mr-1 ml-1 btn-labeled" @click="editPage(detail_pengajuan.id)"><span
                        class="btn-label"><i class="fa fa-pencil"></i></span>Ubah</b-button>
            </template>
            <b-button @click="$bvModal.hide('m-detail-pengajuan')" variant="dark">
                Tutup
            </b-button>
            <!-- (detail_pengajuan.vendor_type == 0 && detail_pengajuan.flag == 13) || (detail_pengajuan.vendor_type == 1 && detail_pengajuan.flag == 3) -->
            <b-button v-if="detail_pengajuan.flag == 13 || detail_pengajuan.flag == 3 || detail_pengajuan.flag == 12" @click="openModalUploadBukti"
                v-b-modal.m-upload-bukti variant="pgiBtn" class="ml-2">
                <i class="fa fa-upload"></i>
                Upload Bukti
            </b-button>
        </div>
    </div>
</template>
<script>
import ItemKeyVal from '../persetujuansurattugas/itemKeyVal.vue';
export default {
    props: ['query'],
    components: {
        ItemKeyVal
    },
    data() {
        return {
            fieldsMaterial: [
                {
                    key: "tipe",
                    label: "Material/Bahan",
                    thClass: "nameOfTheClassTh",
                },
                {
                    key: "quantity",
                    label: "Jumlah",
                    thClass: "nameOfTheClassThQuantity",
                    tdClass: "nameOfTheClassTd",
                },
            ],
            role: localStorage.getItem("role")
        }
    },
    methods: {
        handleImageError(e) {
            e.target.src = "/img/brand/user.png"
        },
        openModalUploadBukti() {
            this.$bvModal.hide("m-detail-pengajuan")
            this.$store.dispatch("createSelectedId", this.detail_pengajuan.id)
        },
        confirmCancel: function (id) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });

            this.$swal({
                html: "<strong>Konfirmasi</strong>, untuk membatalkan pengajuan ini.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Konfirmasi",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        title: "Menunggu",
                        html: "Permintaan anda sedang diproses nih...",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        willOpen: () => {
                            this.$swal.showLoading();
                        },
                    });

                    this.$axios
                        .post("/api/internal-memo/memo/cancel/" + id)
                        .then((response) => {
                            if (response.status == 200) {
                                Toast.fire({
                                    // icon: "success",
                                    iconHtml: `
                                        <div class="m-2">
                                            <i class="fa fa-check"></i>
                                        </div>
                                        `,
                                    iconColor: '#a5dc86',
                                    html: "Pengajuan anda berhasil dibatalkan",
                                });
                                // this.onReset();
                                // this.showModalDetail(id);
                                this.$bvModal.hide("m-detail-pengajuan")
                                // this.$store.dispatch("createSelectedId", this.detail_pengajuan.id)
                                this.$store.dispatch("getDaftarPengajuan", this.query);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            Toast.fire({
                                iconHtml: `
                                    <div>
                                        <i class="fa fa-times"></i>
                                    </div>
                                    `,
                                iconColor: '#dc3545',
                                html: "Pengajuan anda gagal dibatalkan",
                            });
                        });
                }
            });
        },
        editPage: function (id) {
            this.$router.push("ubah-pengajuan/" + id);
        },
    },
    computed: {
        detail_pengajuan() {
            return this.$store.state.pengajuan.detail_pengajuan
        }
    }
}
</script>


<style lang="scss">
.video-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 6px;
    overflow: hidden;

    video {
        object-fit: contain;
        border: none;
        width: 100%;
        height: 100%;
    }
}

.item-petugas {
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 0 2px rgba($color: #000000, $alpha: .2);
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px;

    .foto-petugas-img {
        width: 60px;
        height: 60px;
        background-color: #FFF;
        border: 1px solid #DDD;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 5px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .info-petugas {
        .name {
            font-weight: 600;
        }

        .hp {
            font-size: 12px;
        }
    }
}
</style>