
<template>
    <div v-if="detail_pengajuan.vendor_type === 0">
        <template v-if="detail_pengajuan.memo_maintenance.length">
            <div>
                <div v-for="item in detail_pengajuan.memo_maintenance" :key="item.id">
                    <div class="item-petugas" v-for="um in item.user_maintenance" :key="um.id">
                        <div class="d-flex w-75">
                            <div class="foto-petugas-img p-1">
                                <img @error="handleImageError" :src="um.foto" alt="">
                            </div>
                            <div class="info-petugas">
                                <div class="name">
                                    {{ um.nama }}
                                </div>
                                <div class="hp">{{ um.no_telp }}</div>
                                <div class="hp">
                                    {{ item.date | moment("D MMM Y") }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <small class="d-block">Kode Absen</small>
                            <b class="bg-dark">{{ item.kode }}</b>
                            <button class="btn btn-sm p-1" @click="copyTestingCode">
                                <i class="fa fa-copy"></i>
                            </button>
                            <input type="hidden" id="copy-code-absensi" :value="item.kode">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-center">
                BELUM ADA PETUGAS DIJADWALKAN
            </div>
        </template>
    </div>
</template>
<script>
import ItemKeyVal from '../persetujuansurattugas/itemKeyVal.vue';
export default {
    components: {
        ItemKeyVal
    },
    data() {
        return {
            fieldsMaterial: [
                {
                    key: "tipe",
                    label: "Material/Bahan",
                    thClass: "nameOfTheClassTh",
                },
                {
                    key: "quantity",
                    label: "Jumlah",
                    thClass: "nameOfTheClassThQuantity",
                    tdClass: "nameOfTheClassTd",
                },
            ],
        }
    },
    methods: {
        handleImageError(e) {
            e.target.src = "/img/brand/user.png"
        },
        copyTestingCode() {
            let testingCodeToCopy = document.querySelector('#copy-code-absensi')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()

            try {
                var successful = document.execCommand('copy');
                if (successful) {
                    this.$helper.toastSucc(this, `Kode absensi telah tercopy`)
                }

            } catch (err) {
                console.log(err)
            }
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        }
    },
    computed: {
        detail_pengajuan() {
            return this.$store.state.pengajuan.detail_pengajuan
        }
    }
}
</script>


<style lang="scss">
.video-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 6px;
    overflow: hidden;

    video {
        object-fit: contain;
        border: none;
        width: 100%;
        height: 100%;
    }
}

.item-petugas {
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 0 2px rgba($color: #000000, $alpha: .2);
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px;

    .foto-petugas-img {
        width: 60px;
        height: 60px;
        background-color: #FFF;
        border: 1px solid #DDD;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 5px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .info-petugas {
        .name {
            font-weight: 600;
        }

        .hp {
            font-size: 12px;
        }
    }
}
</style>