<template>
  <div class="animated fadeIn">
    <b-card>
      <template v-slot:header>
        <h5>Daftar FPP</h5>
      </template>
      <FormFilterPengajuan />
      <TablePengajuan />
    </b-card>
  </div>
</template>


<script>
import TablePengajuan from '../../components/fpp/pengajuan/tablePengajuan.vue';
import FormFilterPengajuan from '../../components/fpp/pengajuan/formFilterPengajuan.vue';
export default {
  components: {
    TablePengajuan,
    FormFilterPengajuan
  },
  data() {
    return {

    }
  },
  mounted() {
    this.$store.dispatch("getCityFiltered")
  }
}
</script>