<template>
    <div>
        <div class="row mb-3">
            <div class="col-lg-4">
                <b-form @submit.prevent="handleSearch">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-button @click="$bvModal.show('m-filter')" variant="pgiBtn">
                                <i class="fa fa-filter"></i> Cari
                            </b-button>
                        </b-input-group-prepend>
                        <b-form-input @change="setQuery('im_number', $event)" placeholder="Cari No Pengajuan"
                            v-model="selectedImNumber"></b-form-input>
                        <b-input-group-append>
                            <b-button type="submit" variant="pgiBtn"><i class="fa fa-search"></i></b-button>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-button variant="dark" @click="onResetQuery">
                                <i class="fa fa-refresh"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form>
            </div>
        </div>
        <b-modal id="m-filter" size="lg" title="Filter Internal Memo" no-close-on-backdrop no-close-on-esc hide-footer>
            <b-form @submit.prevent="handleSearch">
                <div class="row">
                    <div class="col-lg-4">
                        <b-form-group label="Filter tanggal">
                            <date-range-picker class="w-100" v-model="dateRange" ref="picker"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                                :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true"
                                :showDropdowns="true" :autoApply="true" @select="handleSelectDate">
                            </date-range-picker>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <b-form-group label="Kota/Kabupaten">
                            <div class="form-group">
                                <multiselect @select="setQuery('kabupaten_kota_id', $event)" class="w-100"
                                    placeholder="Pilih Kabupaten/Kota" label="text" track-by="id" v-model="selectKabKot"
                                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="false"
                                    :options="filtered_cities"></multiselect>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <b-form-group label="Cabang">
                            <div class="form-group">
                                <multiselect :multiple="false" @select="setQuery('id_cabang', $event)" class="w-100"
                                    placeholder="Pilih Cabang" label="name" track-by="id" v-model="selectedCabang"
                                    :show-labels="false" :close-on-select="true" :clear-on-select="false"
                                    :options="cabangs">
                                </multiselect>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <b-form-group label="Filter Pengajuan">
                            <div class="form-group">
                                <multiselect @select="setQuery('id_kategori_sub_fpp', $event)" class="w-100"
                                    placeholder="Pilih Pengajuan" label="name" track-by="id" v-model="selectedPengajuan"
                                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="false"
                                    :options="kategori_sub"></multiselect>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <b-form-group label="Filter Jenis Pengajuan">
                            <div class="form-group">
                                <multiselect class="w-100" @select="setQuery('id_kategori_jenis_fpp', $event)"
                                    placeholder="Jenis Pengajuan" label="name" track-by="id"
                                    v-model="selectedJenisPengajuan" :show-labels="false" :close-on-select="true"
                                    :clear-on-select="false" :multiple="false" :options="kategori_jenis"></multiselect>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <b-form-group label="Status">
                            <div class="form-group">
                                <multiselect :multiple="false" @select="setQuery('flag', $event)" class="w-100"
                                    placeholder="Pilih Status" label="name" track-by="id" v-model="selectedStatus"
                                    :show-labels="false" :close-on-select="true" :clear-on-select="false"
                                    :options="optionStatus">
                                </multiselect>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col align-items-center mb-1 d-flex justify-content-end">
                        <b-button type="submit" variant="pgiBtn">
                            <i class="fa fa-search"></i> Cari
                        </b-button>
                        <b-button variant="dark" @click="onResetQuery" class="ml-1">
                            <i class="fa fa-refresh"></i> Reset
                        </b-button>
                    </div>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import { mapState } from 'vuex'
export default {
    components: { DateRangePicker },
    data() {
        return {
            filter: {
                page: 1,
                per_page: 10
            },
            optionsFilter: [
                {
                    value: null,
                    disabled: true,
                    text: "filter berdasarkan",
                },
                {
                    value: "kabkot",
                    text: "Kabupaten/Kota",
                },
                {
                    value: "status",
                    text: "Status",
                },
                {
                    value: "id_cabang",
                    text: "Cabang",
                },
                {
                    value: "jenis-kategori",
                    text: "Jenis Pengajuan",
                },
                {
                    value: "kategori",
                    text: "Kategori Pengajuan",
                },
                {
                    value: "sub-kategori",
                    text: "Pengajuan",
                }
            ],
            optionStatus: [
                {
                    id: 3,
                    name: "Diproses"
                },
                {
                    id: 13,
                    name: "Diselesaikan"
                },
            ],
            selectedStatus: null,
            selectedImNumber: null,
            selectKabKot: null,
            selectedCabang: null,
            selectedPengajuan: null,
            selectedJenisPengajuan: null,
            selectedKategoriPengajuan: null,
            dateRange: { startDate: null, endDate: null },
        }
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        filtered_cities() {
            return this.$store.state.wilayah.filtered_cities
        },
        queryParams() {
            return this.$store.state.global_query.queryParams
        },
        cabangs() {
            return JSON.parse(localStorage.getItem("cabang"))
        },
        kategori_sub() {
            return this.$store.state.fpp.kategori_sub
        },
        kategori_jenis() {
            return this.$store.state.fpp.kategori_jenis
        },
        kategori_im() {
            return this.$store.state.fpp.kategori_im
        },
    },
    methods: {
        handleSelectDate(value) {
            const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
            const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
            this.filter.startDate = startDate
            this.filter.endDate = endDate
            this.$store.dispatch("createQueryParams", {
                ...this.filter,
                ...this.queryParams
            })
        },
        handleSearch() {
            this.$store.dispatch("getDaftarPengajuan", this.queryParams)
            this.$bvModal.hide("m-filter")
        },
        setQuery(type, value) {
            this.$store.dispatch("createQueryParams", {
                ...this.queryParams,
                ...this.filter,
                [type]: type === 'im_number' ? value : value.id,
            })
        },
        onResetQuery() {
            this.$store.dispatch("createQueryParams", {
                page: 1,
                per_page: 10
            })
            this.filterBy = 'kabkot'
            this.selectedStatus = null
            this.selectedImNumber = null
            this.selectKabKot = null
            this.selectedCabang = null
            this.selectedPengajuan = null
            this.selectedJenisPengajuan = null
            this.selectedKategoriPengajuan = null
            this.dateRange = { startDate: null, endDate: null }
            this.$store.dispatch("getDaftarPengajuan", this.queryParams)
        }
    },
    mounted() {
        this.$store.dispatch("getCategoryJenis")
        this.$store.dispatch("getCategorySub")
        this.$store.dispatch("getCategoryIM")
    }
}
</script>